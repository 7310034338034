
<script>
  export let xLabels = ['a', 'b', 'c', 'c', 'c', 'c', 'c', 'a', 'b', 'c', 'c', 'c', 'c', 'c']
  export let dataSet = [
    {
      name: 'average', color: 'var(--graph-color-one)', data: [10, 20, 30, 40, 50, 60, 70, 10, 20, 30, 40, 50, 60, 70],
    },
    {
      name: 'worst', color: 'var(--graph-color-two)', data: [20, 30, 40, 50, 60, 70, 80, 20, 30, 40, 50, 60, 70, 80],
    }
  ]
  export let numberOfYSegments = 4

  const xGroups = xLabels.map((v, i) => ({
    xLabel: v,
    data: dataSet.map(d => ({color: d.color, value: d.data[i]}))
  }))

  let max = Math.max(60, ...dataSet.map(x => x.data).reduce((acc, v) => acc.concat(v), [])) * 1.1
  let ySegmentSize = max / (numberOfYSegments - 1)
  let ySegments = Array(numberOfYSegments).fill(0).map((v, i) => i * ySegmentSize).reverse()
</script>

<div class='legend'>
  {#each dataSet as set}
    <div style={`--circle-color: ${set.color}`} >
      {set.name}
    </div>
  {/each}
</div>

<div class='graph'>
  <div class='y-labels'>
    {#each ySegments as segment, i}
      <div style='top: {i * 100 / (numberOfYSegments - 1)}%' >{segment.toFixed(0)}ms</div>
    {/each}
  </div>
  <div class='content-wrapper'>
    <div class='content'>
      <div class='lines'>
        {#each ySegments as segment, i}
          <div style='top: {i * 100 / (numberOfYSegments - 1)}%' />
        {/each}
      </div>
      {#each xGroups as group}
        <div class='x-group' >
          <div class='bars'>
            {#each group.data as data}
              <div style='--bar-color: {data.color}; --bar-height: {data.value / max * 100}%;' />
            {/each}
          </div>
          <div class='x-label'>{group.xLabel}</div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .legend {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  .legend > div:not(:last-child) {
    margin-right: 1em;
  }

  .legend > div:before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    font-size: 0.7em;
    background-color: var(--circle-color);
    margin-right: 0.5em;
  }

  .legend > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .graph {
    height: 25em;
    max-height: 90vh;
    display: flex;
    flex-flow: row nowrap;
  }

  .y-labels {
    position: relative;
    text-align: right;
    margin-right: 1.5em;
    margin-bottom: 4em;
  }

  .y-labels > div {
    margin-top: -0.6em;
  }

  .y-labels > div:not(:first-child) {
    position: absolute;
    right: 0;
  }

  .content-wrapper {
    padding-bottom: 4em;
    overflow-x: auto;
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
  }
  .content {
    height: 100%;
    /* width: 100%; */
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    justify-content: space-between;
  }

  .content .lines {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .content .lines > div {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--dark-border-color);
  }

  .x-group {
    z-index: 1;
    text-align: center;
    margin: 0 0.5em;
    /* display: flex; */
    /* flex-flow: column nowrap; */
    /* align-items: center; */
    /* padding: 0.5em; */
  }

  .bars {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: center;
  }

  .bars > div {
    background-color: var(--bar-color);
    width: 0.7em;
    height: var(--bar-height);
  }

  .bars > div:not(:last-child) {
    margin-right: 0.5em;
  }

  .x-label {
    padding-top: 1.5em;
    white-space: nowrap;
  }

</style>
