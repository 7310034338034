<script>
  import {clickOutside} from '/util/clickOutside'
  import {modal} from '/store'
  import Close from '/icons/Close.svelte'
  import ContainerButton from '/elements/buttons/ContainerButton.svelte'
</script>

<div class='modal' use:clickOutside on:click_outside={() => modal.set(null)} >
  <ContainerButton on:click={() => modal.set(null)} >
    <Close />
  </ContainerButton>
  <slot></slot>
</div>

<style lang='less' >
  .modal {
    background: white;
    border-radius: 5px;
    width: 30em;
    min-height: 20em;
    position: relative;
    padding: 0 2em;
  }

  .modal > :global(button:first-child) {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  .modal :global(h3) {
    font-size: 1.5em;
    text-align: center;
    font-weight: 400;
    margin-top: 3rem;
    margin-bottom: 3rem;
    line-height: 1.5;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
  }

  .modal :global(form) {
    display: flex;
    flex-flow: column nowrap;
    max-width: 20em;
    margin: 0 auto;
  }
  .modal :global(form > *:not(last-child)) {
    margin-bottom: 3em;
  }

  .modal :global(pre) {
    border: 1px solid var(--primary-accent-color);
    border-radius: 5px;
    padding: 1em;
    color: var(--primary-accent-color);
    white-space: pre-wrap;
    word-wrap: break-word; 
  }

   .modal :global(.button-group) {
     margin: 1em auto 0;
   }
</style>
