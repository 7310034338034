import {writable, derived, get} from 'svelte/store'
import url from '/util/url'
import localStorage from '/util/localStorage'

export const routePath = writable(window.location.pathname + window.location.search)
export const route = writable({})
export const notFound = derived([routePath, route], ([$routePath, $route]) => $routePath != $route.matchedPath)
export const apiKey = writable(localStorage.getItem('apiKey'))
export const account = writable()
export const dropdown = writable()
export const modal = writable()
export const modalState = writable()
export const token = writable(localStorage.getItem('token'))
export const requests = writable({})
