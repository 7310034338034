
<script>
  import url from '/util/url'
  import fetch from '/util/fetch'
  import useRequest from '/util/useRequest'
  import SubmitButton from '/elements/form/SubmitButton.svelte'
  const account = useRequest(url('api.account'))

  let submitting = false

  const onSubmit = () => {
    submitting = true
    fetch(url('api.accountSubscribe'), {method: 'PATCH', body: {}})
      .then(res => { 
        account.load() 
        submitting = false
      })
  }

</script>

<form on:submit|preventDefault={onSubmit} >
  <SubmitButton class='g-button-primary' submitting={submitting} >
    Activate Plan
  </SubmitButton>
</form>
