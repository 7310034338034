<script>
  import {get} from 'svelte/store'
  import ErrorMessage from '/elements/ErrorMessage.svelte'
  import LineGraph from '/elements/graph/LineGraph.svelte'
  import BarGraph from '/elements/graph/BarGraph.svelte'
  import Graph from '/icons/Graph.svelte'
  import Table from '/elements/Table.svelte'
  import Loading from '/elements/Loading.svelte'
  import SectionHeader from '/elements/SectionHeader.svelte'
  import Toggle from '/elements/Toggle.svelte'
  import {route, apiKey} from '/store'
  import url from '/util/url'
  import fetch from '/util/fetch'
  export let urlName
  export let urlArgs = {}
  export let headers = {}

  let viewMode = 'graph'
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let time = 'months' 

  const ms = val => (val * 1000).toFixed(0)

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const timeString = (string, time) => {
    const [year, month, day] = string.split('T')[0].split('-').map(x => parseInt(x))
    const monthString = monthNames[month - 1]

    if (time === 'years') {
      return `${year}`
    }

    if (time === 'months') {
      return `${monthString} ${year}`
    }

    return `${monthString} ${day}`
  }
</script>

<svelte:head>
  <title>Endpoint Stats Over Time | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

<div class='c g-content-width' >
  <SectionHeader title='Performance Over Time' icon='url(/assets/Graph.svg)' spaceBetween>
      <div class='level'>
        <select bind:value={time} >
          <option value='days' >Day</option>
          <option value='weeks' >Week</option>
          <option value='months' >Month</option>
          <option value='years' >Year</option>
        </select>
          <Toggle bind:value={viewMode} name='viewMode' options={[{label: 'Table View', value: 'table'}, {label: 'Graph View', value: 'graph'}]} />
      </div>
  </SectionHeader>

  {#await fetch(url(urlName, {...urlArgs, endpointIdentifier: $route.args.endpointIdentifier}, {time, timezone: btoa(timezone)}), {headers})}
    <Loading page />
  {:then result}
    {#if viewMode == 'graph' }
      <BarGraph
        xLabels={
            result.results.slice(-8).map(x => timeString(x.start, time))
        }
        dataSet={[
          {
            name: 'average',
            color: 'var(--graph-color-one)',
            data: result.results.slice(-8).map(x => ms(x.averageTime))
          },
          {
            name: 'worst',
            color: 'var(--graph-color-two)',
            data: result.results.slice(-8).map(x => ms(x.problemTime))
          }
        ]}
      />
    {:else}
      <div class='table-wrapper'>
        <Table>
          <tr>
            <th>Date</th>
            <th>Total requests</th>
            <th>Average time</th>
            <th>Average SQL time</th>
            <th>Average SQL queries</th>
            <th>Problem time</th>
            <th>Problem SQL time</th>
          </tr>
          {#each result.results.slice().reverse() as stat}
            <tr>
              <td>{timeString(stat.start, time)}</td>
              <td>{stat.totalRequests}</td>
              <td>{ms(stat.averageTime)}</td>
              <td>{ms(stat.averageSqlTime)}</td>
              <td>{stat.averageSqlQueries.toFixed(0)}</td>
              <td>{ms(stat.problemTime)}</td>
              <td>{ms(stat.problemSqlTime)}</td>
            </tr>
          {/each}
        </Table>
      </div>
    {/if}
  {:catch err}
    <ErrorMessage>
      Something went wrong :(
    </ErrorMessage>
  {/await}
</div>

<style lang='less'>
.c {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

select {
  outline: 0;
  background: none;
  border: 1px solid var(--dark-border-color);
  appearance: none;
  -webkit-appearance: none;
  padding: 0.5em 3.25em 0.5em 1.35em;
  border-radius: 2em;
  font-size: .9rem;
  background: white url(/assets/caretDown.svg) no-repeat calc(100% - .35em) center;
  background-image: url(/assets/caretDown.svg);
  background-size: 1.35em 1.35em;
  margin-right: .75em;

    @media screen and (max-width: 500px) {
      font-size: .7rem;
    }
}

.line-graph-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.line-graph-wrapper :global(.line-graph-component) {
  min-width: 35em;
  max-width: 50em;
}


</style>
