<script>
  import {get} from 'svelte/store'
  import {route as routeStore} from '/store'
  import url from '/util/url'
  import Loading from '/elements/Loading.svelte'
  import Stairs from '/icons/Stairs.svelte'
  import route from '/router/route'
  import Table from '/elements/Table.svelte'
  import AllEndpointsTable from '/elements/AllEndpointsTable.svelte'
</script>

<svelte:head>
  <title>Stats | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

<div class='c g-content-width' >
  <div class='g-header header' >
    <div>
      <h1>All Endpoints</h1>
    </div>
  </div>
  <AllEndpointsTable
    fetchArgs={[url('api.stats', {apiKeyId: $routeStore.args.apiKeyId}, {limit: 15})]} 
    endpointUrl={id => url('endpointStats', {apiKeyId: $routeStore.args.apiKeyId, endpointIdentifier: id})}
    instructionsUrl={url('getStarted', {apiKeyId: $routeStore.args.apiKeyId})}
  />
</div>

<style>

  .c {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    overflow: auto;
  }

  .header {
    margin-bottom: 2em;
  }

  .header h1, .header p {
    margin: 0;
    padding-bottom: 1rem;
    max-width: 30em;
  }

  .detail {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 3em;
  }

  .detail > *:first-child {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .detail :global(svg) {
    margin-right: 0.5em;
  }

  .detail > * {
    margin-bottom: 1em;
  }

  .detail button {
    border: none;
    border-radius: 2em;
    background: var(--button-background-color);
    padding: 0.75em 2em;
    color: var(--primary-accent-color);
    text-decoration: none;
    text-transform: uppercase;
    transition: background 200ms, color 200ms;
    font-family: 'SpaceMono';
    white-space: nowrap;
    cursor: pointer;
  }

  @media(hover) {
    .detail button:hover {
      background: var(--primary-accent-color);
      color: white;
    }
  }

</style>
