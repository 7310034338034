
<script>
  import url from '/util/url'
  import {route} from '/store'
  import GetStarted from '/elements/GetStarted.svelte'
</script>

<GetStarted
  apiKey='<YOUR_API_KEY_HERE>'
  statsUrl={url('stats', {apiKeyId: $route.args.apiKeyId})}
/>
