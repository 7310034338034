<script>
  import Router from '/router/Router.svelte'
  import CtaGetStarted from '/elements/CtaGetStarted.svelte'
  import HeroPage from '/elements/HeroPage.svelte'
  import PricingSlider from './PricingSlider.svelte'
  import { STRIPE_KEY, STRIPE_BASE_COST_DOLLARS, STRIPE_FREE_REQUESTS, STRIPE_COST_PER_REQUEST } from '/constants/settings'
</script>

<svelte:head>
  <title>Pricing | Moonroof</title>
  <meta name="description" content="Enhance Django performance with Moonroof. Your first { STRIPE_FREE_REQUESTS } Requests are free!" />
</svelte:head>

<HeroPage heading='Pricing' image='hero-pricing.png' dark>
  <p>
    Your first { STRIPE_FREE_REQUESTS } Requests are free! After you pass that threshhold, plans begin at a starting rate of ${ STRIPE_BASE_COST_DOLLARS }/mo, plus ${STRIPE_COST_PER_REQUEST}/request (${STRIPE_COST_PER_REQUEST * 1000000} per 1,000,000 requests).
  </p>
</HeroPage>

<PricingSlider />
<CtaGetStarted />