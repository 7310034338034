<script>
  import { LottiePlayer } from '@lottiefiles/svelte-lottie-player'
  import IntersectionObserver from '/elements/IntersectionObserver.svelte'
  export let lottieFile
  export let headline
  export let introText
</script>

<div class='featurette-w-points'>
  <div class='g-content-width'>

    <div class='image-copy'>

      <div class='lottie'>
      
      <IntersectionObserver let:intersecting top={0}>

        <LottiePlayer
          src={lottieFile}
          autoplay={{intersecting}}
          loop={true}
          controls={false}
          renderer="svg"
          width=''
          height=''
          background="transparent"
          controlsLayout
        />

      </IntersectionObserver>

      </div>

      <div class='copy'>
        <h2>{headline}</h2>
        <p>{introText}</p>
      </div>

    </div>

    <div class='points'>
      <slot />
    </div>

  </div>
</div>

<style lang='less'>
  .featurette-w-points {
    color: var(--dark-background-color);
    padding: 1em 0 6em;

    @media screen and (max-width: 1100px) {
      padding-top: 3em;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 5em;
    }

    .image-copy {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .lottie {
        width: 49%;
        position: relative;
        padding-bottom: 3em;
        min-height: 525px;

       @media screen and (max-width: 768px) {
          min-height: 200px;
        }

        @media screen and (max-width: 1100px) {
          margin-top: 0;
        }

        &:before {
          content: '';
          display: block;
          background-color: black;
          width: 1px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          display: none;
        }

        &:after {
          content: '';
          display: block;
          background-color: white;
          border: solid 1px black;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          bottom: -7px;
          left: 0;
          right: 0;
          z-index: 1;
          margin: 0 auto;
          display: none;
        }

        @media screen and (max-width: 1060px) {
          &:after,
          &:before {
            display: none;
          }
        }

        @media screen and (max-width: 768px) {
          width: 35%;
        }

        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      .copy {
        width: 43%;

        @media screen and (max-width: 1060px) {
          font-size: 90%;
        }

        @media screen and (max-width: 768px) {
          width: 60%;
          font-size: 85%;
          margin-bottom: 2em;
        }

        @media screen and (max-width: 600px) {
          width: 100%;
          max-width: 400px;
          margin-left: auto;
        }

        h2,
        p {
          color: currentColor;
          padding: 0;
          margin: 0;
          text-align: right;
        }

        h2 {
          font-size: 2.2em;
          line-height: 1.5;
          margin-left: auto;
          font-weight: normal;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &:after {
            display: block;
            content: '';
            width: 5em;
            height: 3px;
            margin: 1em 0 0.8em 0;
            background-color: var(--primary-accent-color);
          }
        }

        p {
          font-size: 1.26em;
          line-height: 1.5;
          margin: 0 0 2em 0;
          margin-left: auto;
        }
      }
    }

    .points {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-top: solid 1px black;
      position: relative;

      @media screen and (max-width: 900px) {
        font-size: 90%;
      }

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      &:after {
        content: '';
        display: block;
        background-color: white;
        border: solid 1px black;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: -6px;
        right: 0;
        z-index: 1;

        @media screen and (max-width: 768px) {
          & {
            display: none;
          }
        }
      }
    }
  }
</style>
