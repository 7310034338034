<script>
  import SecondaryAction from '/elements/SecondaryAction.svelte'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import FormPageHeader from '/elements/FormPageHeader.svelte'
  import FormPageFormContainer from '/elements/FormPageFormContainer.svelte'
  import Input from '/elements/inputs/Input.svelte'
</script>

<svelte:head>
  <title>Reset Password Successful! | Moonroof</title>
  <meta name="description" content="Your password has been reset. Moonroof is your Django toolkit for enhanced performance." />
</svelte:head>

<FormPageHeader title='Success' >
  Your password has been changed!
</FormPageHeader>

<FormPageFormContainer>
  <form>
    <div class="form-row">
      <a href={url('login')} class='g-button-primary' >
        Login
      </a>
    </div>
  </form>
</FormPageFormContainer>

