<script>
  import Loading from '/elements/Loading.svelte'
  import EndpointSummary from '/elements/EndpointSummary.svelte'
  import EndpointStatsOverTime from '/elements/EndpointStatsOverTime.svelte'
  import {get} from 'svelte/store'
  import {apiKey, route} from '/store'
  import url from '/util/url'

  const getStats = async (encodedEndpoint) => {
    try {
      const response = await fetch(
        url('api.noUserEndpointStats', {endpointIdentifier: encodedEndpoint}),
        {headers: {Authorization: `Bearer ${get(apiKey)}`}}
      )
      let json = await response.json()
      if (response.status > 299) {
        throw "Bad response status"
      }
      return json
    } catch (err) {
      console.error(err)
      throw err
    }
  }
</script>

{#await getStats($route.args.endpointIdentifier)}
  <Loading page />
{:then result}
  <div class='c' >
    <hr />
    <EndpointSummary stats={result} />
    <hr />
    <EndpointStatsOverTime
      urlName={'api.noUserEndpointStatsOverTime'}
      headers={{Authorization: `Bearer ${get(apiKey)}`}}
    />
  </div>
{:catch err}
  <div>Error :(</div>
{/await}

<style>
  hr:first-child {
    margin-bottom: 3em;
  }
  hr:not(:first-child) {
    margin: 3em 0;
  }
</style>
