<script>
  import Back from '/elements/back/index.svelte'
  import SectionHeader from '/elements/SectionHeader.svelte'
  import StatPositive from '/icons/StatPositive.svelte'
  import StatNeutral from '/icons/StatNeutral.svelte'
  import StatNegative from '/icons/StatNegative.svelte'
  import url from '/util/url'
  import msString from '/util/msString'
  import {route} from '/store'
  export let stats
  let {
    route: routeDef,
    method,
    totalRequests,
    statusCode,
  } = stats

  const numbers = [
    {
      name: 'Average Time',
      key: 'averageTime',
      ranking: [0.2, 0.5],
      convert: v => `${msString(v)}ms`
    },
    {
      name: 'Problem Time',
      key: 'problemResponse',
      ranking: [0.2, 0.5],
      convert: v => `${msString(v)}ms`
    },
    {
      name: 'Average SQL Time',
      key: 'averageSqlTime',
      ranking: [0.2, 0.5],
      convert: v => `${msString(v)}ms`
    },
    {
      name: 'Average SQL Queries',
      key: 'averageSqlQueries',
      ranking: [3, 6],
      convert: v => v.toFixed(1)
    },
  ]

</script>

<svelte:head>
  <title>{atob($route.args.endpointIdentifier).split('::')[1]} | Moonroof</title>
</svelte:head>

<div class='c g-content-width' >
  <Back href={$route.args.apiKeyId ? url('stats', {apiKeyId: $route.args.apiKeyId}) : url( 'noUserStats')} >
    Back to Endpoints
  </Back>
  <h2>{atob($route.args.endpointIdentifier).split('::')[1]}</h2>
  <SectionHeader title='Overview' icon='url(/assets/Stairs.svg)' />
  <div class='all' >
    <ul class='styled-list'>
      <li>Route Definition <span class='value'>{routeDef}</span></li>
      <li>Method <span class='value'>{method}</span></li>
      <li>Status Code <span class='value'>{statusCode}</span></li>
      <li>Total requests <span class='value'>{totalRequests}</span></li>
    </ul>
    <div class='numbers'>
      {#each numbers as number}
        <div>
          {#if stats[number.key] < number.ranking[0]}
            <StatPositive />
          {:else if stats[number.key] < number.ranking[1]}
            <StatNeutral />
          {:else}
            <StatNegative />
          {/if}
          <div>
            <div>{number.name}</div>
            <div>{number.convert(stats[number.key])}</div>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang='less'>
  h2 {
    color: var(--primary-accent-color);
    font-size: 2em;
    font-weight: 300;
    margin-bottom: 1.5em;
  }

  .numbers {
    align-self: flex-start;
    display: grid;
    grid-gap: 1em;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    
    @media screen and (max-width: 820px) {
     margin-top: 2em;
    }
  }

  .numbers > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .numbers > div > :global(svg) {
    margin-right: 1em;
    flex-shrink: 0;
  }

  .numbers > div > div > div:first-child {
    color: var(--muted-text-color);
    font-size: 0.9em;
    margin-bottom: 0.5em;
  }

  .numbers > div > div > div:last-child {
    color: var(--primary-accent-color);
    font-weight: 600;
    font-size: 0.9em;
  }

  .all {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 820px) {
      display: block;
    }
  }

</style>
