<script>
  import SecondaryAction from '/elements/SecondaryAction.svelte'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import FormPageHeader from '/elements/FormPageHeader.svelte'
  import FormPageFormContainer from '/elements/FormPageFormContainer.svelte'
  import Input from '/elements/inputs/Input.svelte'
</script>

<svelte:head>
  <title>Success! Password reset | Moonroof</title>
  <meta name="description" content="Password reset has been successful. Moonroof is your Django toolkit for enhanced performance." />
</svelte:head>

<FormPageHeader title='Success' >
  Check your email for a reset password link.
</FormPageHeader>

<FormPageFormContainer>
  <form>
    <div class="form-row">
      <a href={url('login')} class='g-button-primary' >
        Login
      </a>
    </div>
    <div class="form-row">
      <SecondaryAction>
        <span>Don't have an account?</span>
        <a href={url('signup')} > Sign up.</a>
      </SecondaryAction>
    </div>
  </form>
</FormPageFormContainer>

