<script>
  import {token, route, modal, dropdown} from '/store'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import Dropdown from '/elements/dropdown/Dropdown.svelte'
  import DropdownTrigger from '/elements/dropdown/DropdownTrigger.svelte'
  import useRequest from '/util/useRequest.js'
  const account = useRequest(url('api.account'))
  const me = useRequest(url('api.me'))
  const onClickCreateNewKey = () => {
    modal.set('ModalCreateAPIKey')
    dropdown.set()
  }
  account.load()
  me.load()
</script>

<div class='c' >
  {#if !$account}
    <Loading />
  {:else}
    <div>
      <DropdownTrigger let:onClick={onClick} identifier='api-keys'>
        <button class='dropdown-trigger' on:click={onClick}>
          <span class='desktop'>{($account.apiKeys.find(k => `${k.id}` === $route.args.apiKeyId) || {name: 'Keys'}).name}</span>
        <span class='mobile'>Keys</span>
        </button>
      </DropdownTrigger>
      <Dropdown identifier='api-keys' >
        <ul class='keys'>
          {#each $account.apiKeys as key}
          <li>
            <a
              class:active={`${key.id}` == $route.args.apiKeyId}
              href={url('stats', {apiKeyId: key.id})} 
            >
              {key.name}
            </a>
          </li>
          {/each}
          <li><button class='add-new' on:click={onClickCreateNewKey}>Add new API key</button></li>
        </ul>
      </Dropdown>
    </div>
  {/if}
  <a href={url('account')} class='avatar'>{#if !$me}{:else}{$me.email[0]}{/if}</a>
</div>

<style lang='less'>

.mobile {
  display: none;
}

@media screen and (max-width: 700px) {
  .mobile {
    display: inline;
  }
  .desktop {
    display: none;
  }
}

.c {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > *:last-child {
    margin-left: 2em;
  }
}

.avatar {
  height: 2.4em;
  width: 2.4em;
  background: var(--primary-accent-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 2em;
}

div :global(.dropdown) {
  right: 1em;
  top: 1em;
}

div .dropdown-trigger {
  color: var(--primary-accent-color);
  text-transform: uppercase;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;

  &:after {
    content: '';
    display: inline-block;
    height: 1em;
    width: 1em;
    background-image: url(/assets/CaretDownBlue.svg);
    margin-left: 0.8em;
  }
}

.keys {
  margin: 0;
  padding: 0;
  // To remedy text shift during transform animation
  transform: perspective(1px) translateZ(0);
}

.keys li {
  text-align: right;
  display: block;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.9em;
  min-width: 270px;

  @media screen and (max-width: 700px) {
    min-width: auto;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
}

.keys li a {
  color: var(--text-color);
  padding: 0.9em 2.25em;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: var(--primary-accent-color);
  }
}

.keys .active {
  color: var(--primary-accent-color);
  text-decoration: none;
  background: url(/assets/icons/icon-edit-primary.svg) no-repeat 0.75em center;
  background-size: 1em 1em;
}

button.add-new {
  text-align: right;
  display: inline-block;
  margin: 0;
  font-size: 1em;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0.75em center;
  background-size: 1em 1em;
  background-image: url(/assets/icons/icon-add.svg);
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--muted-text-color);
  padding: 0.9em 2.25em;

  &:hover {
    color: var(--primary-accent-color);
    background-image: url(/assets/icons/icon-add-primary.svg);
  }
}

</style>
