<script>
  import IntersectionObserver from '/elements/IntersectionObserver.svelte'
  import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
  export let heading;
  export let lottieFile;
  export let alternate;
  export let extraClass;
</script>

<div class={`featurette ${alternate ? 'alternate' : ''} ${extraClass ? extraClass : ''}`}>
  <div class='g-content-width'>
    <div class='lottie'>

      <IntersectionObserver let:intersecting top={0}>

          <LottiePlayer
            src={lottieFile}
            autoplay={{intersecting}}
            loop={true}
            controls={false}
            renderer="svg"
            width=''
            height=''
            background="transparent"
            controlsLayout
          />

      </IntersectionObserver>

    </div>
    <div class='content'>
      <h2>{heading}</h2>
      <p>
        <slot />
      </p>
    </div>
  </div>
</div>

<style lang='less'>
  .featurette {
  color: var(--dark-background-color);
  text-align: right;
  padding: 3em 0;

  @media screen and (max-width: 600px) {
    font-size: 85%;
  }

  .lottie {
    width: 44%;
    margin-left: 2em;
    @media only screen and (max-width: 768px) {
      width: 37%;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      max-width: 400px;
      margin-bottom: 2em;
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    &.loop1 {
      padding: 4em 0;
      .lottie {
        width: 50%;
        min-height: 426px;
      }
    }

    &.loop2 {
      padding: 7.5em 0;
      .lottie {
        width: 63%;
         min-height: 320px;
      }
    }

    &.loop3 {
      .lottie {
        width: 44%;
        min-height: 493px;
      }
    }

  }

  &.alternate {
    background-color: var(--dark-background-color);
    color: white;
    text-align: left;

    .lottie {
      order: 2;
    }

    h2 {
      align-items: flex-start;
      margin-left: unset;

      &:after {
        background-color: var(--secondary-accent-color);
      }
    }

    p {
      margin-left: unset;
    }
  }

  .g-content-width {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
      flex-direction: column;

      .content {
        order: 2;
      }
    }
  }

  .content {
    flex: 1;
    max-width: 490px;
  }

  h2,
  p {
    color: currentColor;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 2.2em;
    line-height: 1.5;
    margin-left: auto;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media only screen and (max-width: 600px) {
      line-height: 1.1;
      max-width: 250px;
    }

    &:after {
      display: block;
      content: '';
      width: 5em;
      height: 3px;
      margin: 0.5em 0 1em;
      background-color: var(--primary-accent-color);
    }
  }

  p {
    font-size: 1.26em;
    line-height: 1.5;
    margin: 0;
    margin-left: auto;

    @media only screen and (max-width: 600px) {
      font-size: 1.15em;
      line-height: 1.3;
    }
  }
}

</style>
