<script>
  import path from '@wasmuth/path'
  import SecondaryAction from '/elements/SecondaryAction.svelte'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import {token as tokenStore, account as accountStore} from '/store'
  import fetch from '/util/fetch'
  import localStorage from '/util/localStorage'
  import routeTo from '/router/route'
  import FormPageHeader from '/elements/FormPageHeader.svelte'
  import FormPageFormContainer from '/elements/FormPageFormContainer.svelte'
  import Input from '/elements/inputs/Input.svelte'
  let email, password, globalError, submitting = false

  const onSubmit = (ev) => {
    ev.preventDefault()
    submitting = true
    fetch(url('api.login'), {method: 'POST', body: {username: email, password}})
      .then(({token}) => {
        tokenStore.set(token)
        localStorage.setItem('token', token)
        routeTo(url('account'))
        if (window.yardstick !== undefined) {
          window.yardstick.trackEvent('user', 'login')
          //window.yardstick.identify(id, name)
        }
      })
      .catch((err) => {
        const text = path(['json', 'non_field_errors'], err)
        globalError = text || 'An unknown error occured, please try again later'
        if (window.yardstick !== undefined) {
          window.yardstick.trackEvent('user', 'loginError', {error: err})
        }
      })
      .finally(() => {
        submitting = false
      })
  }
</script>

<svelte:head>
  <title>Login | Moonroof</title>
  <meta name="description" content="Log in to your Moonroof account. Moonroof is your Django toolkit for enhanced performance." />
</svelte:head>

{#if globalError}
  <span class='global-error' >{globalError}</span>
{/if}

<FormPageHeader title='Log In'>
  
</FormPageHeader>

<FormPageFormContainer>
  <form class='api-key-form' on:submit={onSubmit} >
    <div class="form-row">
      <Input type='email' placeholder='Email address' bind:value={email} />
    </div>
    <div class="form-row">
      <Input type='password' placeholder='Password' bind:value={password} />
    </div>
    <div class="form-row">
      <a href={url('forgotPassword')} >Forgot your password?</a>
    </div>
    <div class="form-row">
      <button disabled={!email || !password || submitting} class='g-button-primary' >
        {#if submitting}
          <Loading />
        {:else}
          Log in
        {/if}
      </button>
    </div>
    <div class="form-row">
      <SecondaryAction>
        <span>Don't have an account?</span>
        <a href={url('signup')} > Sign up.</a>
      </SecondaryAction>
    </div>
  </form>
</FormPageFormContainer>

