const pathAndDefinitionMatch = (path, def) => {
  let pathPieces = (path[0] === '/' ? path.slice(1) : path).split('?')[0].split('/')
  let defPieces = def.split('/')
  let match = {}
  if (pathPieces.length != defPieces.length) {
    return null
  }
  for (let piece in pathPieces) {
    let val = pathPieces[piece]
    let defVal = defPieces[piece]
    if (defVal[0] == ':') {
      match[defVal.slice(1)] = val
    } else if (defVal != val) {
      return null
    }
  }
  return match
}

export const findMatchingRoute = (routes, currentPath) => {
  for (let name in routes) {
    const route = routes[name]
    if (route.routes) {
      const match = findMatchingRoute(route.routes, currentPath)
      if (match) {
        return {
          component: route.component,
          args: match.args,
          definition: match.definition
        }
      }
    } else {
      const args = pathAndDefinitionMatch(currentPath, route.path.slice(route.path[0] === '/' ? 1 : 0))
      if (args) {
        return {
          component: route.component,
          args,
          definition: {name, path: route.path}
        }
      }
    }
  }
}
