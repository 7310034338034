<script>
  import {apiKey} from '/store'
  import Stats from './Stats.svelte'
  import StatsAPIKeyForm from './StatsAPIKeyForm.svelte'
</script>

{#if $apiKey}
  <Stats />
{:else}
  <div class='form-wrapper'>
    <StatsAPIKeyForm />
  </div>
{/if}

<style>
  .form-wrapper {
    padding-top: 2em;
    padding: 1em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
</style>
