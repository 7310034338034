<script>
  import {get} from 'svelte/store'
  import Modal from '/modal/Modal.svelte'
  import Input from '/elements/inputs/Input.svelte'
  import Loading from '/elements/Loading.svelte'
  import fetch from '/util/fetch'
  import url from '/util/url'
  import {modal, modalState} from '/store'
  import useRequest from '/util/useRequest.js'
  const account = useRequest(url('api.account'))

  let {name, id: apiKeyId} = get(modalState)
  let submitting
  let errors = {}

  const onSubmit = () => {
    submitting = true
    fetch(url('api.apiKey', { apiKeyId }),
      { method: 'PATCH', body: { name } })
      .then(res => {
        modal.set(null)
        account.load()
      })
      .catch(err => {
        errors = err.json
        submitting = false
      })
  }

</script>

<Modal>
  <h3>Edit</h3>
  <form on:submit|preventDefault={onSubmit} >
    <Input placeholder='Give your API key a name' bind:value={name} bind:error={errors.name} />
    <button class='g-button-primary' disabled={!name || submitting} >
      {#if submitting}
        <Loading />
      {:else}
        Save
      {/if}
    </button>
  </form>
</Modal>
