<script>
  import Modal from '/modal/Modal.svelte'
  import Input from '/elements/inputs/Input.svelte'
  import Loading from '/elements/Loading.svelte'
  import fetch from '/util/fetch'
  import url from '/util/url'
  import {modal} from '/store'
  import useRequest from '/util/useRequest.js'
  const account = useRequest(url('api.account'))

  let name, apiKeySecret, submitting
  let errors = {}

  const onSubmit = () => {
    submitting = true
    fetch(url('api.accountAttachAPIKey'),
      { method: 'PATCH', body: { apiKeySecret, name } })
      .then(res => {
        modal.set()
        account.load()
      })
      .catch(err => {
        errors = err.json
        submitting = false
      })
  }

</script>

<Modal>
  <h3>Add an existing API key to your account.</h3>
  <form on:submit|preventDefault={onSubmit} >
    <Input type='password' placeholder='API Key' bind:value={apiKeySecret} bind:error={errors.apiKeySecret} />
    <Input placeholder='Give your API key a name' bind:value={name} bind:error={errors.name} />
    <button class='g-button-primary' disabled={!name || submitting} >
      {#if submitting}
        <Loading />
      {:else}
        Attach
      {/if}
    </button>
  </form>
</Modal>
