<script>
  import url from '/util/url'
  import Loading from '/elements/Loading.svelte'
  import AccountOverview from './AccountOverview.svelte'
  import BillingOverview from './BillingOverview.svelte'
  import APIKeys from './APIKeys.svelte'
  import Usage from './Usage.svelte'
  import LogOut from './LogOut.svelte'
  import useRequest from '/util/useRequest.js'
  const account = useRequest(url('api.account'))
  const me = useRequest(url('api.me'))
  account.load()
  me.load()
</script>

<svelte:head>
  <title>Account Overview | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

{#if !$account || !$me}
  <div class='loading-wrapper'><Loading /></div>
{:else}
  <div>

    <div class='module slim'>
      <div class='g-content-width'>
        <h1>Account Overview</h1>
      </div>
    </div>

    <div class='module'>
      <div class='g-content-width'>
        <APIKeys account={$account} />
      </div>
    </div>

    <div class='module two-col'>
      <div class='g-content-width'>
        <div class='col'>
          <AccountOverview user={$me} />
        </div>

        <div class='col'>
          <BillingOverview account={$account} />
        </div>
      </div>
    </div>

    <div class='module'>
      <div class='g-content-width'>
        <Usage />
      </div>
    </div>

    <div class='module'>
      <div class='g-content-width'>
        <LogOut />
      </div>
    </div>
  </div>
{/if}

<style lang='less'>
  h1 {
    margin: 0;
    font-size: 2.25em;
  }

  .module {
    border-bottom: solid 1px var(--border-color);
    padding: 3.5em 0;
    box-sizing: border-box;

    @media screen and (max-width: 600px) {
      font-size: 75%;
    }

    &.slim {
      padding: 2.5em 0;
    }

    @media screen and (max-width: 500px) {
      padding: 2em 0;
    }

    &:last-child {
      border: none;
    }

    &.two-col {
      padding: 0;

      .g-content-width {
        display: flex;

        @media screen and (max-width: 1175px) {
          display: block;
        }

        .col {
          padding: 3.5em 0;

          @media screen and (max-width: 500px) {
            padding: 2em 0;
          }

          &:first-child {
            width: 36%;

            @media screen and (max-width: 1175px) {
              width: 100%;
            }
          }

          &:last-child {
            border-left: solid 1px var(--border-color);
            padding-left: 3.5em;
            flex: 1;

            @media screen and (max-width: 1175px) {
              border-top: solid 1px var(--border-color);
              border-left: 0;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
</style>
