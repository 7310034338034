<script>
  import path from '@wasmuth/path'
  import SecondaryAction from '/elements/SecondaryAction.svelte'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import {token as tokenStore, account as accountStore} from '/store'
  import fetch from '/util/fetch'
  import localStorage from '/util/localStorage'
  import routeTo from '/router/route'
  import FormPageHeader from '/elements/FormPageHeader.svelte'
  import FormPageFormContainer from '/elements/FormPageFormContainer.svelte'
  import Input from '/elements/inputs/Input.svelte'
  let email, globalError, submitting = false

  const onSubmit = (ev) => {
    ev.preventDefault()
    submitting = true
    fetch(url('api.forgotPassword'), {method: 'POST', body: {email}})
      .then(() => {
        routeTo(url('forgotPasswordSuccess'))
      })
      .catch((err) => {
        globalError = 'An unexpected error occured.'
      })
      .finally(() => {
        submitting = false
      })
  }
</script>

<svelte:head>
  <title>Forgot Password | Moonroof</title>
  <meta name="description" content="Forgot your password? Moonroof is your Django toolkit for enhanced performance." />
</svelte:head>

{#if globalError}
  <span class='global-error' >{globalError}</span>
{/if}

<FormPageHeader title='Forgot Password' />

<FormPageFormContainer>
  <form class='api-key-form' on:submit={onSubmit} >
    <div class="form-row">
      <Input type='email' placeholder='Email address' bind:value={email} />
    </div>
    <div class="form-row">
      <button disabled={!email || submitting} class='g-button-primary' >
        {#if submitting}
          <Loading />
        {:else}
          Submit
        {/if}
      </button>
    </div>
    <div class="form-row">
      <SecondaryAction>
        <span>Don't have an account?</span>
        <a href={url('signup')} > Sign up.</a>
      </SecondaryAction>
    </div>
  </form>
</FormPageFormContainer>

