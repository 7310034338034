<script>
  import { apiKey } from "/store";
  import url from "/util/url";
  import { STRIPE_KEY, STRIPE_BASE_COST_DOLLARS, STRIPE_FREE_REQUESTS, STRIPE_COST_PER_REQUEST } from '/constants/settings'

  let value = 10
  let max = 50
  let freeRequests = STRIPE_FREE_REQUESTS
  let costPerRequest = STRIPE_COST_PER_REQUEST
  const oneSignificantDigit = number => {
    let str = `${Math.floor(number)}`
    return parseInt(str.slice(0, 2).padEnd(str.length, "0"))
    // return str
  };
  const requests = v => oneSignificantDigit(Math.pow(10, v / 7))
  const cost = requests =>
    `$${(requests <= freeRequests
      ? 0
      : STRIPE_BASE_COST_DOLLARS + (requests - freeRequests) * costPerRequest
    ).toFixed(2)}`;

  const markerPosition = (x, y) => (x / y) * 100
</script>

<div class="pricing-slider">

  <div class="g-content-width">

    <div class="top-copy">
      <h2>Requests per Month</h2>
      <p>Try dragging the slider below to find your monthly plan</p>
    </div>

    <div class="slider-wrapper">
      <input
        style={`
          background: linear-gradient(to right,
          #3C4FFF ${markerPosition(value, max)}%, #eaecff 0);
        `}
        class="slider"
        type="range"
        min="0"
        {max}
        bind:value
        step="1" />
      <div class="bar" style={`width: ${markerPosition(value, max)}%;`} />
      <div
        class={`marker ${markerPosition(value, max) > 80 ? 'stack' : ''}`}
        style={`left: ${markerPosition(value, max)}%;`}>
        <div class="col">{requests(value).toLocaleString()}</div>
        <div class="col">{cost(requests(value))}/mo</div>
      </div>
    </div>

    <div class="bottom-copy">
      <h3>{cost(requests(value))} / Month</h3>
      <a href={url('signup')} class="g-button-primary">Create an account</a>
      <div class="tooltip">
        <span class="trigger">How is this price generated?</span>
        <span class="tip">
          Your first { STRIPE_FREE_REQUESTS } Requests are free! After you pass that threshhold, plans begin at a starting rate of ${ STRIPE_BASE_COST_DOLLARS }/mo, plus ${STRIPE_COST_PER_REQUEST}/request (${STRIPE_COST_PER_REQUEST * 1000000} per 1,000,000 requests).
        </span>
      </div>

    </div>
  </div>
</div>

<style lang="less">
  .pricing-slider {
    padding: 1em 0 2em;
  }

  .top-copy {
    text-align: center;
    margin-bottom: 5em;

    h2 {
      font-weight: normal;
      font-size: 1.5em;
      margin: 0 0 0.25em 0;
    }

    p {
      margin: 0;
      font-size: 1.15em;
    }
  }

  .slider-wrapper {
    width: 100%;
    max-width: 776px;
    margin: 2em auto;
    position: relative;
    z-index: 100;

    @media screen and (max-width: 920px) {
      width: 80%;
    }

    @media screen and (max-width: 600px) {
      width: 75%;
      transform: translateX(-11px);
    }

    .bar {
      height: 11px;
      width: 100px;
      background: red;
      position: absolute;
      top: 3px;
      border-top-left-radius: 2em;
      border-bottom-left-radius: 2em;
      padding-right: 6px;
      display: none;
    }

    .marker {
      position: absolute;
      top: 40px;
      border: solid 1px var(--primary-accent-color);
      border-radius: 0.3rem;
      display: flex;
      transform: translateX(-41px);

      @media screen and (max-width: 1100px) {
        &.stack {
          flex-direction: column;

          .col:first-child {
            border-top-left-radius: 0.2rem;
            border-top-right-radius: 0.2rem;
            border-bottom-left-radius: 0;
          }
        }
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      .col {
        width: 85px;
        text-align: center;
        font-size: 0.94rem;
        padding: 0.5rem;
        position: relative;
        color: var(--primary-accent-color);
      }

      .col:first-child {
        color: white;
        background-color: var(--primary-accent-color);
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;

        @media screen and (max-width: 600px) {
          border-top-left-radius: 0.2rem;
          border-top-right-radius: 0.2rem;
          border-bottom-left-radius: 0;
        }

        &::after {
          content: "";
          top: -7px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid var(--primary-accent-color);
        }
      }
    }

    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 11px;
      background: #eaecff;
      outline: none;
      transition: opacity 0.2s;
      border-radius: 2em;
      margin: 0;
      width: ~"calc(100% + 19px)";
    }

    .slider::-moz-focus-outer {
      border: 0;
    }

    .slider:hover {
      opacity: 1;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      background: white;
      border: solid 2px var(--primary-accent-color);
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      z-index: 200;
      transition: box-shadow 0.25s ease-in-out;
      &:active {
        box-shadow: 0px 0 3px 3px rgba(60, 78, 255, 0.25);
      }
    }
    .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: white;
      border: solid 2px var(--primary-accent-color);
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      z-index: 200;
      transition: box-shadow 0.25s ease-in-out;
      &:active {
        box-shadow: 0px 0 3px 3px rgba(60, 78, 255, 0.25);
      }
    }
  }

  .bottom-copy {
    text-align: center;
    margin: 7em 0 2em 0;

    h3 {
      margin: 0 0 1.25em 0;
      font-size: 1.5em;
      color: var(--primary-accent-color);
      font-weight: normal;
    }

    .g-button-primary {
      margin-bottom: 2em;
    }
  }

  .tooltip {
    position: relative;

    .trigger {
      color: var(--muted-text-color);
      font-size: 1em;
      cursor: pointer;
      background: url(/assets/icon-info.svg) no-repeat left center;
      background-size: 1em 1em;
      padding-left: 1.75em;
    }

    .tip {
      opacity: 0;
      max-width: 320px;
      background-color: var(--primary-accent-color);
      color: white;
      text-align: center;
      font-size: 0.75em;
      padding: 1em 0.85em;
      border-radius: 0.3rem;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 15px;
      position: absolute;
      transition: all 0.25s ease-in-out;

      &::after {
        content: "";
        top: -7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid var(--primary-accent-color);
      }
    }

    .trigger:hover + .tip {
      opacity: 1;
      margin-top: 18px;
    }
  }
</style>
