import routeDefinitions from '/constants/routes'

const objectToQuery = (object) =>
  Object
    .keys(object)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(object[key]))
    .join('&')

export default (name, args = {}, query = {}) => {
  const pieces = name.split('.')
  const namespace = pieces.length === 2 ? pieces[0] : ''
  name = pieces.length === 2 ? pieces[1] : pieces[0]

  const scheme = routeDefinitions.find(s => s.namespace === namespace)
  if (!scheme) console.warn('No scheme with namespace', namespace)

  const rule = scheme.routes[name]
  if (!rule && rule != '') console.warn('No route named', name, 'in namespace', namespace)

  const path = Object
    .keys(args)
    .reduce((acc, k) => acc.replace(`:${k}`, args[k]), rule)

  const hasQueries = Object.keys(query).length > 0

  return `${scheme.url}/${path}${hasQueries ? `?${objectToQuery(query)}` : ''}`
}
