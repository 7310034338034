<script>
  import Logo from '/elements/Logo.svelte'
  import url from '/util/url'
  import {route, token, apiKey} from '/store'
  import Account from './Account.svelte'
</script>

<div class:dark={['landing', 'pricing'].includes(($route.definition || {}).name)} class:has-border={['account'].includes(($route.definition || {}).name)} >
  <div class='g-content-width' >
    <a href={url('landing')}><Logo darkBackground={['landing', 'pricing'].includes(($route.definition || {}).name)} /></a>
    <nav class="main">
      <ul>
        {#if !$token}
          <li><a href={url('pricing')}>Pricing</a></li>
        {/if}
        <li>
          {#if $token}
            <Account />
          {:else if $apiKey && !($route.definition || {name: ''}).name.toLowerCase().includes('stats')}
            <a href={url('noUserStats')} class="g-button-primary">View my stats</a>
          {:else}
            <a href={url('signup')} class='g-button-primary' >Create an Account</a>
          {/if}
        </li>
      </ul>
    </nav>
  </div>
</div>

<style lang='less'>
  div > div {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .has-border {
    border-bottom: solid 1px var(--border-color);
  }

  .g-content-width {
    display: flex;
    justify-content: space-between;
  }

  nav.main {
    @media (max-width: 600px) {
      font-size: 85%;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      @media (max-width: 950px) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    li {
      list-style: none;
      margin: 0;
      text-transform: uppercase;
      font: 1.1em "SpaceMono";

      & + li {
        margin-left: 2.55em;

        @media (max-width: 950px) {
          margin-left: 0;
          margin-top: 0.75em;
        }
      }
    }

    a {
      color: var(--primary-accent-color);;
      text-decoration: none;

      &:hover {
        color: var(--secondary-accent-color);
      }
    }

    .g-button-primary {
      padding: 0.5rem 2.3rem;
      font-size: 0.9em;

      @media (max-width: 600px) {
        display: none;
      }

      &:hover {
        color: white;
      }
    }
  }

  .dark {
    background-color: var(--dark-background-color);

    nav.main {
      a:not(.g-button-primary) {
        color: white;

        &:hover {
          color: var(--primary-accent-color);
        }
      }
      .g-button-primary {
        color: var(--primary-accent-color);
          &:hover {
            color: white;
          }
      }
    }
  }

  div.g-content-width {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
  /* div.g-content-width  > * { */
  /*   margin-bottom: 1em; */
  /* } */
</style>



