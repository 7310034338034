import getEnvironment from '/util/getEnvironment'

// use the command line command `ipconfig getifaddr en0` to get
// your machines local ip address. If you change 'localhost' to that value
// then you can test on mobile device.
const LOCAL_IP = 'localhost'

export const environment = getEnvironment({
  development: ['localhost:5000', `${LOCAL_IP}:5000`],
  staging: host => host.includes('-moonroof.netlify.app'),
  production: ['moonroof.netlify.com', 'moonroof.io', 'www.moonroof.io']
})


export const API_URL = {
  // development: `http://${LOCAL_IP}:8000/api`,
  development: 'https://moonroof-api-staging.herokuapp.com/api',
  staging: `https://moonroof-api-staging.herokuapp.com/api`,
  production: 'https://moonroof-api-prod.herokuapp.com/api'
}[environment]

export const STRIPE_KEY = {
  development: 'pk_test_yriAscIaCns3iqRmYCaYvxLV006i157w2O',
  staging: 'pk_test_DQEfsjKsC55JuayD47asLfmE00zR4eAJL7',
  production: 'pk_live_7ItFUINCGRK9JWmpbr3Mth6A00lGDVS9ZP'
}[environment]

export const STRIPE_FREE_REQUESTS = 10000
export const STRIPE_BASE_COST_DOLLARS = 20
export const STRIPE_COST_PER_REQUEST = 0.00003
