<script>
  import {get} from 'svelte/store'
  import Modal from '/modal/Modal.svelte'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import useRequest from '/util/useRequest.js'

  const invoiceDate = invoice => {
    const start = new Date(invoice.periodStart * 1000)
    const end = new Date(invoice.periodEnd * 1000)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const string = date => [months[date.getMonth()], `${date.getDate()}`, `${date.getFullYear()}`].join(' ')
    return `${string(start)} - ${string(end)}`
  }

  const invoices = useRequest(url('api.accountInvoices'))
  invoices.load()

  $: console.log($invoices)
</script>

<Modal>
  <h3>Invoices</h3>
  {#if $invoices}
   <ul class='styled-list'>
    {#each $invoices.invoices as invoice}
     
        {#if invoice.hostedInvoiceUrl}
        <li>
          <a href={invoice.invoicePdf} target='_blank' data-external-link >
            ${(invoice.amountDue / 100).toFixed(2)} {invoiceDate(invoice)} - {invoice.status}
          </a>
        </li>
        {:else}
          <li>
            ${(invoice.amountDue / 100).toFixed(2)} {invoiceDate(invoice)} - {invoice.status == 'draft' ? 'upcoming' : invoice.status}
          </li>
        {/if}
    {/each}
    </ul>
  {:else}
    <div class='loading-wrapper'><Loading /></div>
  {/if}
</Modal>
