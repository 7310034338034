<script>
  import {onDestroy} from 'svelte'
  import {writable, get} from 'svelte/store'
  import './listener'
  import {routePath, route, notFound} from '/store'
  import {findMatchingRoute} from './index'
  export let routes
  let Component

  const unsubscribe = routePath.subscribe((routePath) => {
    let match = findMatchingRoute(routes, routePath)
    let matchedPath = get(route).matchedPath
    if (match) {
      if (routePath != matchedPath) {
        Component = match.component
        route.set({matchedPath: routePath, ...match})
      }
    }
  })

  onDestroy(() => console.log('unsubscribe') || unsubscribe())
</script>

<svelte:component this={$notFound ? null : Component} />
