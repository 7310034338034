<script>
  import {modal} from '/store'
  export let modals
  let modalComponent
  $: modalComponent = modals[$modal]
  $: $modal && !modalComponent && console.warn('No modal found with name', $modal)
</script>

<div class='container' class:active={$modal} >
  {#if $modal}
    <svelte:component this={modalComponent} />
  {/if}
</div>

<style>
  .container {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container.active {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

</style>
