<script>
  import path from '@wasmuth/path'
  import {get} from 'svelte/store'
  import {route} from '/store'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import fetch from '/util/fetch'
  import localStorage from '/util/localStorage'
  import routeTo from '/router/route'
  import FormPageHeader from '/elements/FormPageHeader.svelte'
  import FormPageFormContainer from '/elements/FormPageFormContainer.svelte'
  import Input from '/elements/inputs/Input.svelte'
  let password, globalError, submitting = false

  const onSubmit = (ev) => {
    ev.preventDefault()
    const {token, userId} = get(route).args
    submitting = true
    fetch(url('api.resetPassword'), {method: 'POST', body: {password, userId, token}})
      .then(() => {
        routeTo(url('resetPasswordSuccess'))
      })
      .catch((err) => {
        globalError = 'An unexpected error occured.'
      })
      .finally(() => {
        submitting = false
      })
  }
</script>

<svelte:head>
  <title>Reset Password | Moonroof</title>
  <meta name="description" content="Reset Your Password. Moonroof is your Django toolkit for enhanced performance." />
</svelte:head>

{#if globalError}
  <span class='global-error' >{globalError}</span>
{/if}

<FormPageHeader title='Reset Password' />

<FormPageFormContainer>
  <form class='api-key-form' on:submit={onSubmit} >
    <div class="form-row">
      <Input type='password' placeholder='New password' bind:value={password} />
    </div>
    <div class="form-row">
      <button disabled={!password || submitting} class='g-button-primary' >
        {#if submitting}
          <Loading />
        {:else}
          Submit
        {/if}
      </button>
    </div>
  </form>
</FormPageFormContainer>

