<script>
  import {get} from 'svelte/store'
  import {apiKey} from '/store'
  import url from '/util/url'
  import localStorage from '/util/localStorage'
  import Loading from '/elements/Loading.svelte'
  import Stairs from '/icons/Stairs.svelte'
  import route from '/router/route'
  import Table from '/elements/Table.svelte'
  import AllEndpointsTable from '/elements/AllEndpointsTable.svelte'
</script>

<div class='c g-content-width' >
  <div class='g-header header' >
    <div>
      <h1>All Endpoints</h1>
      <p>You're using a free test API Key. It's limited to 1000 requests. To remove this limit, you'll need to <a href={url('signup')}>create an account.</a></p>
    </div>
    <a href={url('signup')} class='g-button-primary' >Create an account</a>
  </div>
  <AllEndpointsTable
    fetchArgs={[url('api.noUserStats', {}, {limit: 15}), {headers: {Authorization: `Bearer ${get(apiKey)}`}}]}
    endpointUrl={(id) => url('noUserEndpointStats', {endpointIdentifier: id})}
    instructionsUrl={url('noUserGetStarted')}
  />
  <div class='detail' >
    <div>
      <Stairs />
      <span>Showing stats for api key {$apiKey.slice(0, 5)}************</span>
    </div>
    <button on:click={() => {apiKey.set(null); localStorage.removeItem('apiKey');}} >
      Use a different key
    </button>
  </div>
</div>

<style>

  .c {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    overflow: auto;
  }

  .header {
    margin-bottom: 2em;
  }

  .header h1, .header p {
    margin: 0;
    padding-bottom: 1rem;
    max-width: 30em;
  }

  .detail {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 3em;
  }

  .detail > *:first-child {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .detail :global(svg) {
    margin-right: 0.5em;
  }

  .detail > * {
    margin-bottom: 1em;
  }

  .detail button {
    border: none;
    border-radius: 2em;
    background: var(--button-background-color);
    padding: 0.75em 2em;
    color: var(--primary-accent-color);
    text-decoration: none;
    text-transform: uppercase;
    transition: background 200ms, color 200ms;
    font-family: 'SpaceMono';
    white-space: nowrap;
    cursor: pointer;
  }

  @media(hover) {
    .detail button:hover {
      background: var(--primary-accent-color);
      color: white;
    }
  }

</style>
