<script>
  import Icon from './Icon.svelte'
  export let href
</script>

<a href={href} on:click {...$$restProps} >
  <Icon />
  <slot></slot>
</a>

<style>
a {
  color: var(--back-text-color);
  text-decoration: none;
  font-size: 0.9em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

a :global(> *:first-child) {
  padding-right: 0.5em;
}
</style>
