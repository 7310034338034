<script>
  export let center = false
  export let page = false
</script>

<span class:center class:page >
  <span>&bull;</span>
  <span>&bull;</span>
  <span>&bull;</span>
</span>

<style>
  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
  }

  span span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    margin-right: 0.1em;
  }

  span span:last-child {
    margin-right: 0;
  }

  span span:nth-child(2) {
    animation-delay: .2s;
  }

  span span:nth-child(3) {
    animation-delay: .4s;
  }

  span {
    display: inline-flex;
  }

  .page {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 13em 1em;
  }

  .center {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
  }
</style>
