import {get, writable} from 'svelte/store'
import {requests} from '/store'
import fetch from '/util/fetch'

const REQUEST_STORES = {}

export default (url) => {
  if (REQUEST_STORES[url]) {
    return REQUEST_STORES[url]
  }
  const standardStore = writable()
  const store = {
    clear: () => standardStore.set(undefined),
    subscribe: standardStore.subscribe,
    load: () => fetch(url).then(res => standardStore.set(res))
  }
  REQUEST_STORES[url] = store
  return store
}
