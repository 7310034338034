<script>
  import {get} from 'svelte/store'
  import SecondaryAction from '/elements/SecondaryAction.svelte'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  import {token as tokenStore, account as accountStore, apiKey} from '/store'
  import fetch from '/util/fetch'
  import routeTo from '/router/route'
  import localStorage from '/util/localStorage'
  import FormPageHeader from '/elements/FormPageHeader.svelte'
  import FormPageFormContainer from '/elements/FormPageFormContainer.svelte'
  import Input from '/elements/inputs/Input.svelte'
  let email, password, globalError, submitting = false

  const onSubmit = (ev) => {
    ev.preventDefault()
    submitting = true
    fetch(
      url('api.signup'),
      {method: 'POST', body: {email, password}}
    )
      .then(() =>
        fetch(url('api.login'), {method: 'POST', body: {username: email, password}})
      )
      .then(({token}) => {
        tokenStore.set(token)
        localStorage.setItem('token', token)
        const apiKeySecret = get(apiKey)
        if (apiKeySecret) {
          apiKey.set()
          return fetch(
            url('api.accountAttachAPIKey'),
            { method: 'PATCH', body: { apiKeySecret, name: 'Test API Key' } }
          )
        }
        return
      })
      .then(() => {
        routeTo(url('paymentDetails'))
        if (window.yardstick !== undefined) {
          window.yardstick.trackEvent('user', 'signup')
        }
      })
      .catch((err) => {
        globalError = 'An unknown error occured, please try again later'
        if (window.yardstick !== undefined) {
          window.yardstick.trackEvent('user', 'signupError', { error: err })
        }
      })
      .finally(() => {
        submitting = false
      })
  }
</script>

<svelte:head>
  <title>Create Your Account | Moonroof</title>
  <meta name="description" content="Sign up for a Moonroof account! Moonroof is your Django toolkit for enhanced performance." />
</svelte:head>

{#if globalError}
  <span class='global-error' >{globalError}</span>
{/if}

<FormPageHeader title='Create Your Account'>
  You're using a free test API Key. It is limited to 1000 requests and will be deleted after one month unless it is associated with an account. Create an account to get a production API Key.
</FormPageHeader>

<FormPageFormContainer>
  <form class='api-key-form' on:submit={onSubmit} >
    <!-- <input type='email' placeholder='Email address' bind:value={email} /> -->
    <!-- <input type='password' placeholder='Password' bind:value={password} /> -->
    <div class="form-row">
      <Input type='email' placeholder='Email address' bind:value={email} />
    </div>
    <div class="form-row">
      <Input type='password' placeholder='Password' bind:value={password} />
    </div>
    <div class="form-row">
      <button disabled={!email || !password || submitting} class='g-button-primary' >
        {#if submitting}
          <Loading />
        {:else}
          Get Started
        {/if}
      </button>
    </div>
    <div class="form-row">
      <SecondaryAction>
        <span>Already have an account?</span>
        <a href={url('login')} > Log in.</a>
      </SecondaryAction>
    <div class="form-row">
  </form>
</FormPageFormContainer>
