<script>
  export let image;
  import Stars from '/graphics/Stars.svelte'
</script>

<div class='featurette-chart'>

  <div class='g-content-width'>
    <div class='star-group'>
      <Stars />
    </div>
    <div class='star-group'>
      <Stars />
    </div>
    <img src={`/assets/${image}`} alt='' />
  </div>
</div>

<style lang='less'>
  .featurette-chart {
    position: relative;
    background-color: var(--dark-background-color);
    margin-top: 3em;

    @media screen and (max-width: 600px) {
      font-size: 47%;
    }

    .g-content-width {
      position: relative;
      padding-bottom: 5.8em;
    }

    .star-group {
      width: 5.375em;
      height: 5.375em;
      position: absolute;
      left: 1.5em;
      top: 5.25em;

   
      @media screen and (max-width: 600px) {
        display: none;
      }

      &:last-of-type {
        left: unset;
        top: unset;
        right: 1.5em;
        bottom: 4em;
      }
    }

    img {
      width: 75%;
      height: auto;
      margin: 0 auto;
      display: block;
      transform: translateY(-3.875em);

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
</style>
