<script>
  export let dark
  export let heading
  export let image
</script>

<div class='hero' class:dark>
  <div class='g-content-width'>

    <div class='text'>
      <h2>{heading}</h2>
      <slot />
    </div>

    <div class='image'>
      <img src={`/assets/${image}`} alt={heading} />
    </div>
  </div>
</div>

<style lang='less'>
  .hero {
    padding-bottom: 3.6em;
    border-bottom: solid 1px var(--border-color);
    margin-bottom: 3em;
    color: var(--text-color);

    @media screen and (max-width: 600px) {
      font-size: 90%;
    }

    .g-content-width {
      display: flex;
      align-items: center;
    }

    .text {
      width: 36%;

      @media screen and (max-width: 800px) {
        width: 60%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 400px;
      }

      h2 {
        color: var(--primary-accent-color);
        font-family: 'SpaceMono';
        font-weight: normal;
        font-size: 2.2em;
        margin: 0;

        &:after {
          display: block;
          content: '';
          width: 5em;
          height: 3px;
          margin: 0.5em 0 1em;
          background-color: var(--text-color);
        }
      }

      & :global(p) {
        font-size: 1.15em;
        line-height: 1.4;
        margin: 0 0 1em 0;
      }
    }

    .image {
      width: 39%;
      margin-left: 18.5%;

      @media screen and (max-width: 600px) {
        display: none;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &.dark {
      background-color: var(--dark-background-color);
      border-bottom: none;
      color: white;

      h2 {
        color: white;
        &:after {
          background-color: white;
        }
      }
    }
  }
</style>
