
<script>
  import url from '/util/url'
  export let apiKey
  export let statsUrl

  let settings = `MIDDLEWARE = [
  ...,
  'moonroof.MoonroofMiddleware',
  ...
]

MOONROOF_API_KEY = '${apiKey}'`
</script>

<svelte:head>
  <title>Get Started | Moonroof</title>
  <meta name="description" content="Get started by installing Moonroof. 2 Easy steps. Moonroof is your Django toolkit for enhanced performance." />
</svelte:head>

<div class='container g-content-width' >
  <div>
    <h1>Get started.</h1>
    <div class='steps'>
      <div>
        <div>1. Install Moonroof</div>
        <pre>pip install moonroof</pre>
      </div>
      <div>
        <div>2. Update Django settings</div>
        <pre>{settings}</pre>
      </div>
    </div>
    <div class='view-stats' >
      <a href={statsUrl} class='g-button-primary' >View Your Stats</a>
    </div>
  </div>
</div>

<style>

  h1 {
    text-align: center;
  }

  .container {
    display: flex;
    justify-content: center;
  }

  .container > div {
    display: flex;
    flex-flow: column nowrap;
    align-items: fill;
    width: 45em;
    max-width: 100%;
  }

  .steps > * {
    margin-bottom: 2em;
  }

  ol {
    text-align: left;
    color: #aaa;
  }

  pre {
    font-family: 'SpaceMono';
    border: 1px solid var(--pre-border-color);
    padding: 0.75em 1.5em;
    /* color: var(--text-color); */
    overflow-x: auto;
  }

  li {
    margin-bottom: 1.5em;
    /* overflow: scroll; */
  }

  li div {
    overflow: scroll;
  }

  .view-stats {
    margin-top: 2em;
    display: flex;
    justify-content: center;
  }
</style>
