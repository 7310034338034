<script>
  import ErrorMessage from '/elements/ErrorMessage.svelte'
  import fetch from '/util/fetch'
  import url from '/util/url'
  import Table from '/elements/Table.svelte'
  import Loading from '/elements/Loading.svelte'
  export let fetchArgs
  export let endpointUrl
  export let instructionsUrl

  const formatRoute = route => {
    var res = route
    if (route.startsWith('^')) {
      res = res.slice(1)
    }
    if (route.endsWith('$')) {
      res = res.slice(0, -1)
    }
    return res
      .replace(/\(.*\<(.*)\>.*\)/, ':$1')
      .replace(/\<.*:(.*)\>/, ':$1')
      .replace(/\<(.*)\>/, ':$1')
  }

</script>

<svelte:head>
  <title>All Endpoints | Moonroof</title>
</svelte:head>

{#await fetch(...fetchArgs)}
  <Loading center />
{:then result}
  {#if result.count > 0}
    <Table>
      <div>
        <div>Endpoint</div>
        <div>Method</div>
        <div>Status <br />Code</div>
        <div>Typical<br /> Response</div>
        <div>Problem<br /> Response</div>
        <div>Popularity</div>
        <div>Agony</div>
      </div>
      {#each result.results as r}
        <a href='{endpointUrl(r.endpointIdentifier)}' >
          <div title={r.route ? formatRoute(r.route) : r.endpoint}><span class='endpoint-url'>{r.route ? formatRoute(r.route) : r.endpoint}</span></div>
          <div>{r.method}</div>
          <div>{r.statusCode}</div>
          <div>{r.typicalResponse.toFixed(0)}ms</div>
          <div>{r.problemResponse.toFixed(0)}ms</div>
          <div>{r.popularity}</div>
          <div>{r.agony}</div>
        </a>
      {/each}
    </Table>
  {:else}
    <div class='empty-row' ><div>No stats yet. <a href={instructionsUrl}>View setup instructions.</a></div></div>
  {/if}
{:catch err}
  <ErrorMessage>Something went wrong :(</ErrorMessage>
{/await}
