<script>
  import SectionHeader from '/elements/SectionHeader.svelte'
  import useRequest from '/util/useRequest'
  import url from '/util/url'
  import Loading from '/elements/Loading.svelte'
  const usage = useRequest(url('api.accountUsage'))
  usage.load()
  let requestPlan = 500000
</script>

<svelte:head>
  <title>Usage | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

<div>
  <SectionHeader title='Usage' icon='url(/assets/Stairs.svg)' />

  {#if $usage}
    <div class='usage-wrapper'>

      <div class='text'>Requests this billing period</div>

      <div class='usage-bar'>
        <div
          class='count'
          style={`
         left: ${($usage.requestCount / requestPlan) * 100}%;
        `}>
          {$usage.requestCount.toLocaleString()}
        </div>

        <div class='bar'>
          <div
            class='dot'
            style={`
         left: ${($usage.requestCount / requestPlan) * 100}%;
        `} />

          <div
            class='progress'
            style={`
          background: linear-gradient(to right,
          #3C4FFF ${($usage.requestCount / requestPlan) * 100}%, #eaecff 0);
        `} />
        </div>

      </div>

      <div class='cost-breakdown'>

        <div class='col'>{$usage.requestCount.toLocaleString()}</div>
        <div class='col'>${$usage.cost}</div>
      </div>
    </div>
  {:else}
    <Loading />
  {/if}

</div>

<style lang='less'>
  .usage-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .text {
    font-size: 1.1em;

    @media screen and (max-width: 960px) {
      width: 100%;
      margin-bottom: 3em;
    }
  }

  .usage-bar {
    flex: 1;
    margin: 0 4.5em;
    position: relative;

    @media screen and (max-width: 960px) {
      width: 100%;
      margin: 0 0 3em;
      flex: unset;
    }

    @media screen and (max-width: 500px) {
      margin: 2em auto;
      width: 90%;
    }

    .count {
      position: absolute;
      top: -40px;
      z-index: 10;
      transform: translateX(-50%);
      text-align: center;
      font-size: 1.25em;
      color: var(--primary-accent-color);
      font-family: 'SpaceMono';
    }

    .bar {
      width: 100%;
      height: 10px;
      background-color: #eaecff;
      border-radius: 1em;
      position: relative;
      border-radius: 2em;
    }

    .progress {
      height: 10px;
      position: relative;
      border-radius: 2em;
    }

    .dot {
      width: 18px;
      height: 18px;
      position: absolute;
      background-color: var(--primary-accent-color);
      border-radius: 50%;
      right: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  .cost-breakdown {
    display: flex;
    align-items: center;
    border: solid 1px var(--primary-accent-color);
    border-radius: 6px;
    overflow: hidden;

    @media screen and (max-width: 960px) {
      margin: auto;
    }

    .col {
      flex: 1;
      padding: 1em 2.25em;
      color: var(--primary-accent-color);
      font-size: 1.1em;

      &:first-child {
        color: white;
        background-color: var(--primary-accent-color);
      }
    }
  }
</style>
