<script>
	import {onMount} from 'svelte';
  import SecondaryAction from '/elements/SecondaryAction.svelte'
  import Loading from '/elements/Loading.svelte'
  import routeTo from '/router/route'
  import useRequest from '/util/useRequest'
  import url from '/util/url'
  import {token as tokenStore} from '/store'
  import fetch from '/util/fetch'
  import { STRIPE_KEY, STRIPE_BASE_COST_DOLLARS, STRIPE_FREE_REQUESTS, STRIPE_COST_PER_REQUEST } from '/constants/settings'
  import FormPageHeader from '/elements/FormPageHeader.svelte'
  import FormPageFormContainer from '/elements/FormPageFormContainer.svelte'
  let email, password, globalError, submitting = false
  const stripe = Stripe(STRIPE_KEY)
  const account = useRequest(url('api.account'))
  account.load()

  let elements = stripe.elements()
  var cardNumber

	onMount(async () => {
    const style = {
			base: {
				color: '#0E1111',
				fontWeight: 500,
				fontFamily: 'SpaceMono, Consolas, Menlo, monospace',
				fontSize: '15px',
				fontSmoothing: 'antialiased',

				'::placeholder': {
					color: '#6D6D6D',
				},
				':-webkit-autofill': {
					color: '#e39f48',
				},
			},
			invalid: {
				color: '#E25950',

				'::placeholder': {
					color: '#FFCCA5',
				},
			},
		}

    const classes = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid'
    }
    /* paymentIntent = await createIntent(); */
    /* clientSecret = paymentIntent.client_secret; */
    /* console.log(paymentIntent) */
		cardNumber = elements.create('cardNumber', {placeholder: 'Card Number', style, classes})
		var cardExpiry = elements.create('cardExpiry', {placeholder: 'Expiry Date MM/YY', style, classes})
    var cardCvc = elements.create('cardCvc', {placeholder: 'CVC', style, classes})
		cardNumber.mount('#card-number');
		cardExpiry.mount('#card-expiry');
		cardCvc.mount('#card-cvc');
		
  })

  const onSubmit = async (ev) => {
    ev.preventDefault()
    submitting = true
    // apparently stripe uses the cvc and expiry values under the hood...
    /* const token = await stripe.createToken({type: 'card', card: cardNumber, }) */
    const token = await stripe.createToken(cardNumber)
    if (token.error) {
      console.error('Create stripe token error', token.error)
      globalError = 'Something went wrong. Double check your card information.'
      submitting = false
      return
    }
    fetch(url('api.accountPaymentMethod'), {
      method: 'PATCH',
      body: { token: token.token.id }
    })
      .then(({account}) => {
        if (account.stripeSubscriptionId) {
          return
        }
        return fetch(url('api.accountSubscribe'), {method: 'PATCH', body: {}})
      })
      .then(() => {
        account.clear()
        account.load()
        routeTo('account')
        if (window.yardstick !== undefined) {
          window.yardstick.trackEvent('user', 'addPaymentDetails')
        }
      })
      .catch(err => {
        console.error('Update account error', err)
        globalError = 'Something went wrong. Double check your card information.'
        if (window.yardstick !== undefined) {
          window.yardstick.trackEvent('user', 'addPaymentDetailsFailure', { error: err })
        }
      })
      .finally(() => {
        submitting = false
      })
  }
</script>

<svelte:head>
  <title>Payment Details | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

{#if globalError}
  <span class='global-error' >{globalError}</span>
{/if}

<FormPageHeader title='Payment Details'>
  Your first { STRIPE_FREE_REQUESTS } Requests are free! After you pass that threshhold, plans begin at a starting rate of ${ STRIPE_BASE_COST_DOLLARS }/mo, plus ${STRIPE_COST_PER_REQUEST}/request (${STRIPE_COST_PER_REQUEST * 1000000} per 1,000,000 requests).
</FormPageHeader>

<FormPageFormContainer>
  <form on:submit={onSubmit} >
    <div class='form-row' >
      <div class='input-wrapper' >
        <div id='card-number' />
        <div class='baseline' />
      </div>
    </div>
    <div class='double-row' >
      <div class='form-row' >
        <div class='input-wrapper' >
          <div id='card-expiry' />
          <div class='baseline' />
        </div>
      </div>
      <div class='form-row' >
        <div class='input-wrapper' >
          <div id='card-cvc' />
          <div class='baseline' />
        </div>
      </div>
    </div>
    <div class="form-row">
      <p>You wont be charged until you pass the { STRIPE_FREE_REQUESTS } Request threshold. Check your account page to monitor your monthly fee</p>
    </div>
    <div class="form-row">
      <button disabled={submitting} class='g-button-primary' >
        {#if submitting}
          <Loading />
        {:else}
          Save
        {/if}
      </button>
    <div class="form-row">
  </form>
</FormPageFormContainer>

<style>

  .baseline {
    padding-top: 10px;
    border-bottom: 1px solid black;
  }

  .focused + .baseline {
    border-color: var(--primary-accent-color);
  }

</style>
