<script>
  import {clickOutside} from './clickOutside.js';
  import {dropdown} from '/store'
  export let identifier

  const handleClickOutside = (ev) => {
    if (ev.detail.event.target.dataset['dropdownIdentifier'] != identifier) {
      dropdown.set(null)
    }
  }
</script>

{#if $dropdown === identifier}
  <div class='container' >
    <div class='dropdown' use:clickOutside on:click_outside={handleClickOutside}>
      <slot></slot>
    </div>
  </div>
{/if}

<style>
  @keyframes dropdown {
    from {
      opacity: 0;
      top: -1em;
    }
    to {
      opacity: 1;
      top: 1em;
    }
  }
  .container {
    position: relative;
  }

  .dropdown {
    position: absolute;
    background: white;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    animation: dropdown .25s ease;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);
  }
</style>
