<script>
  import Modal from '/modal/Modal.svelte'
  import Input from '/elements/inputs/Input.svelte'
  import Loading from '/elements/Loading.svelte'
  import fetch from '/util/fetch'
  import url from '/util/url'
  import routeTo from '/router/route'
  import {modal, modalState} from '/store'
  import useRequest from '/util/useRequest.js'
  const account = useRequest(url('api.account'))

  let name, submitting

  const onSubmit = (ev) => {
    ev.preventDefault()
    submitting = true
    fetch(url('api.createAPIKey'), {body: {name}})
      .then(res => {
        account.load()
        modalState.set(res)
        modal.set('ModalViewAPIKeySecret')
      })
  }

</script>

<Modal>
  <h3>Create a new API key</h3>
  <form on:submit={onSubmit} >
    <Input placeholder='API Key Name' bind:value={name} on:focus />
    <button class='g-button-primary' disabled={!name || submitting} >
      {#if submitting}
        <Loading />
      {:else}
        Create
      {/if}
    </button>
  </form>
</Modal>
