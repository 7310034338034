<script>
  import localStorage from '/util/localStorage'
  import routeTo from '/router/route'
  import url from '/util/url'
  import {token} from '/store'

  const onClick = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('apiKey')
    token.set(null)
    routeTo(url('landing'))
    window.location.reload(false)
    if (window.yardstick !== undefined) {
      window.yardstick.trackEvent('user', 'signup')
    }
  }
</script>

<button on:click={onClick} class='g-button-primary' >
  Log out
</button>
