<script>
  import Account from '/pages/account/index.svelte'
  import Login from '/pages/auth/Login.svelte'
  import PasswordForgot from '/pages/auth/PasswordForgot.svelte'
  import PasswordForgotSuccess from '/pages/auth/PasswordForgotSuccess.svelte'
  import PasswordReset from '/pages/auth/PasswordReset.svelte'
  import PasswordResetSuccess from '/pages/auth/PasswordResetSuccess.svelte'
  import Signup from '/pages/auth/Signup.svelte'
  import EndpointStats from '/pages/endpoint-stats/EndpointStats.svelte'
  import GetStartedPage from '/pages/get-started/GetStartedPage.svelte'
  import Landing from '/pages/landing/index.svelte'
  import NoUserEndpointStats from '/pages/no-user/EndpointStats.svelte'
  import NoUserGetStartedPage from '/pages/no-user/GetStartedPage.svelte'
  import NoUserStatsPage from '/pages/no-user/StatsPage.svelte'
  import Pricing from '/pages/pricing/index.svelte'
  import StatsPage from '/pages/stats/StatsPage.svelte'
  import PaymentDetails from '/pages/PaymentDetails.svelte'

  import ModalCreateAPIKey from '/modals/ModalCreateAPIKey.svelte'
  import ModalViewAPIKeySecret from '/modals/ModalViewAPIKeySecret.svelte'
  import ModalAttachAPIKey from '/modals/ModalAttachAPIKey.svelte'
  import ModalEditAPIKey from '/modals/ModalEditAPIKey.svelte'
  import ModalDeleteAPIKey from '/modals/ModalDeleteAPIKey.svelte'
  import ModalConfirmUnsubscribe from '/modals/ModalConfirmUnsubscribe.svelte'
  import ModalInvoices from '/modals/ModalInvoices.svelte'
  import Modals from '/modal/Modals.svelte'

  import Header from '/elements/header/Header.svelte'
  import Footer from '/elements/Footer.svelte'
  import Redirect from '/elements/Redirect.svelte'

  import Router from '/router/Router.svelte'
  import NotFound from '/router/NotFound.svelte'
  import url from '/util/url'

  let routes = {
    landing: {
      path: url('landing'),
      component: Landing
    },
    pricing: {
      path: 'pricing',
      component: Pricing
    },
    getStarted: {
      path: url('getStarted'),
      component: GetStartedPage
    },
    noUserGetStarted: {
      path: url('noUserGetStarted'),
      component: NoUserGetStartedPage
    },
    noUserStats: {
      path: url('noUserStats'),
      component: NoUserStatsPage
    },
    noUserEndpointStats: {
      path: url('noUserEndpointStats'),
      component: NoUserEndpointStats
    },
    stats: {
      path: url('stats'),
      component: StatsPage
    },
    endpointStats: {
      path: url('endpointStats'),
      component: EndpointStats
    },
    signUp: {
      path: url('signup'),
      component: Signup
    },
    login: {
      path: url('login'),
      component: Login
    },
    forgotPassword: {
      path: url('forgotPassword'),
      component: PasswordForgot
    },
    forgotPasswordSuccess: {
      path: url('forgotPasswordSuccess'),
      component: PasswordForgotSuccess
    },
    resetPassword: {
      path: url('resetPassword'),
      component: PasswordReset
    },
    resetPasswordSuccess: {
      path: url('resetPasswordSuccess'),
      component: PasswordResetSuccess
    },
    paymentDetails: {
      path: url('paymentDetails'),
      component: PaymentDetails
    },
    account: {
      path: url('account'),
      component: Account
    }
  }
</script>

<main>
  <Header />
  <Router routes={routes} />
  <NotFound />
  <Redirect />
  <Footer />
  <Modals modals={{
    ModalCreateAPIKey: ModalCreateAPIKey,
    ModalViewAPIKeySecret: ModalViewAPIKeySecret,
    ModalAttachAPIKey: ModalAttachAPIKey,
    ModalEditAPIKey: ModalEditAPIKey,
    ModalDeleteAPIKey: ModalDeleteAPIKey,
    ModalConfirmUnsubscribe: ModalConfirmUnsubscribe,
    ModalInvoices: ModalInvoices
  }} />
</main>

<style lang='less'>
  main {
    min-height: 100vh;
  }

  @font-face {
    font-family: 'SpaceMono';
    src: url(/assets/SpaceMono/SpaceMono-Regular.ttf);
    font-style: normal;
  }
  @font-face {
    font-family: 'SpaceMono';
    src: url(/assets/SpaceMono/SpaceMono-Bold.ttf);
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'WorkSans';
    src: url(/assets/WorkSans/WorkSans-VariableFont_wght.ttf);
    font-style: normal;
  }

  :global(body) {
    padding: 0;
    margin: 0;
    background-color: var(--background-color);
    color: black;
    line-height: 1.5;
    font-family: 'WorkSans', 'SpaceMono';
  }

  :global(h1), :global(h2), :global(h3) {
    font-family: 'SpaceMono';
  }

  :global(h1) {
    font-size: 2em;
    font-weight: 400;
    color: var(--primary-accent-color);
  }

  :global(a) {
    color: black;
  }

  :root {
    --primary-accent-color: #3C4FFF;
    --secondary-accent-color: #FF4A60;
    --background-color: white;
    --dark-background-color: black;
    --text-color: #0E1111;
    --placeholder-text-color: #6D6D6D;
    --muted-text-color: #696969;
    --back-text-color: #444646;
    --table-header-text-color: white;
    --table-border-color: #D2D2D2;
    --table-hover-background-color: #EAECFF;
    --element-hover-background-color: #EAECFF;
    --border-color: #E6E6E6;
    --dark-border-color: #D2D2D2;
    --toggle-on-text-color: white;
    --button-background-color: #EAECFF;
    --graph-color-one: var(--primary-accent-color);
    --graph-color-two: #EAECFF;
    --pre-border-color: #0E1111;
  }

  :global(.g-content-width) {
    max-width: 76em;
    padding: 0 3em;
    margin: 0 auto;
  }

  @media only screen and (max-width: 600px) {
    :global(.g-content-width) {
      padding: 0 1.5em;
    }
  }

  :global(.g-header) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  :global(hr) {
    border: 0;
    background: none;
    border-bottom: 1px solid var(--border-color);
    padding: 0;
    margin: 0;
  }

  :global(input),
  :global(select) {
    font-family: 'WorkSans', 'SpaceMono';
  }

  :global(.g-button-primary) {
    font-size: 1rem;
    background: none;
    border: none;
    display: inline-block;
    background: var(--button-background-color);
    border-radius: 2rem;
    padding: 0.75rem 3.5rem;
    color: var(--primary-accent-color);
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    transition: background 200ms, color 200ms;
    font-family: 'SpaceMono';
    white-space: nowrap;
    outline: none;
    cursor: pointer;
  }

  :global(.g-button-primary.small-button) {
    font-size: 0.75rem;
    padding: 0.45rem 1.75rem;
  }

  :global(.g-button-primary:disabled) {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media(hover) {
    :global(.g-button-primary:hover) {
      background: var(--primary-accent-color);
      color: white;
    }
  }

  :global(.loading-wrapper) {
    max-width: 76em;
    padding: 0 3em;
    margin: 3em auto;
    display: flex;
    justify-content: center;
  }

    :global(.styled-list) {
    margin: 0;
    padding: 0;

    :global(li) {
      margin: 0 0 1.75em 0;
      font-size: 1.1em;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      color: var(--muted-text-color);

      &:last-child {
        margin: 0;
      }
    }

    :global(.value) {
      color: var(--primary-accent-color);
      font-weight: 700;
      margin-left: 1em;
    }

    :global(.password) {
      -webkit-text-security: disc;
      font-size: 1.5em;
    }

    :global(a) {
      color: var(--text-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    :global(button) {
      border: 0;
      outline: 0;
      cursor: pointer;
      display: inline-block;
      margin-left: 1em;
      width: 1.5em;
      height: 1.5em;
      text-indent: -9999px;
      padding: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;

      &:hover {
        opacity: 0.7;
      }

      &.edit {
        background-image: url(/assets/icons/icon-edit.svg);
      }
      &.delete {
        background-image: url(/assets/icons/icon-delete.svg);
      }
    }
  }

  :global(.global-error) {
    color: #E82902;
    margin-bottom: 1em;
    text-align: center;
    display: block;
    background-color: #FBDACF;
    padding: 0.5em;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto 1em;
  }

</style>
