import {get} from 'svelte/store'
import {token as tokenStore} from '/store'

// fetch from moonroof api
export default (url, {headers = {}, body, method, ...options} = {}) => {
  let statusCode
  const token = get(tokenStore)
  return fetch(url, {
    ...options,
    ...!!body && {body: JSON.stringify(body)},
    method: method || (body && 'POST') || 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      ...!!(token && !headers.Authorization) && {'Authorization': `Token ${token}`},
    }
  })
    .then(res => {
      statusCode = res.status
      if (method == 'DELETE') {
        return {}
      }
      return res.json()
    })
    .then(json => {
      if (statusCode > 299) {
        throw {statusCode, json}
      }
      return json
    })
    .catch(err => {
      console.error(`fetch error for url ${url}`, err)
      throw err
    })
}

