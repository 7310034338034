<script>
  import Input from '/icons/Input.svelte'
  import url from '/util/url'
</script>

<div class='c g-content-width' >
  <div>&copy; Moonroof {(new Date()).getFullYear()}</div>
  <a href='https://inputlogic.ca' data-external-link target='_blank' ><Input /></a>
</div>

<style>
  .c {
    margin-top: 3em;
    padding-top: 3em;
    padding-bottom: 3em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    font-family: 'SpaceMono';
  }

  .c > div:first-child {
    font-family: 'WorkSans';
  }

  .c > a {
    display: inline-block;
    margin: 2em 0;
  }

  div :global(svg) {
    height: 1.5em;
    width: auto;
  }

</style>


