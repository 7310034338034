import {routePath} from '/store'

export default (url, replaceState, noScroll) => {
  if (replaceState) {
    window.history.replaceState(null, null, url)
  } else {
    window.history.pushState(null, null, url)
  }
  !noScroll && window.scrollTo(0, 0)
  const path = url.startsWith('http')
    ? `/${url.slice(8).split('/').slice(1).join('/')}`
    : url
  routePath.set(path[0] == '/' ? path : `/${path}`)
}
