<script>
  import url from '/util/url'
  import SectionHeader from '/elements/SectionHeader.svelte'
  import {modal, modalState} from '/store'
  export let account
</script>

<div>
  <SectionHeader title='API Keys' icon='url(/assets/User.svg)' >
    <div class='level'>
      <button class='g-button-primary small-button' on:click={() => modal.set('ModalAttachAPIKey')}>Add Existing</button>
      <button class='g-button-primary small-button' on:click={() => modal.set('ModalCreateAPIKey')}>Create API Key</button>
    </div>
  </SectionHeader>

<ul class='styled-list'>
  {#each account.apiKeys as apiKey}
    <li>
      <a href={url('stats', {apiKeyId: apiKey.id})} >{apiKey.name}</a>
      <button class='edit' on:click={() => { modalState.set(apiKey); modal.set('ModalEditAPIKey') }} >Edit</button>
      <button class='delete' on:click={() => { modalState.set(apiKey); modal.set('ModalDeleteAPIKey') }} >Delete</button>
    </li>
  {/each}
  </ul>
</div>
