<script>
  import { apiKey } from '/store'
  import url from '/util/url'
  export let dark
</script>

<div class={`cta-get-started ${dark ? 'dark' : ''}`}>
  <div class='g-content-width'>
    <h2>Get started with Moonroof</h2>
    {#if $apiKey}
      <a href={url('signup')} class='g-button-primary'>Create an account</a>
    {:else}
      <a href={url('noUserGetStarted')} class='g-button-primary'>Get API Key</a>
      <h3>Try it free &mdash; no signup required!</h3>
    {/if}
  </div>
</div>

<style lang='less'>
  .cta-get-started {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 4em 0;
    color: var(--primary-accent-color);
    border-top: solid 1px var(--border-color);
    border-bottom: solid 1px var(--border-color);

    &.dark {
      background-color: var(--dark-background-color);
      color: white;
      border: none;
    }

    h2 {
      margin: 0 0 1em 0;
      font-size: 2.15em;
      color: currentColor;
      font-weight: normal;

      @media screen and (max-width: 600px) {
        font-size: 1.25em;
        margin-bottom: 1.5em;
      }
    }

    h3 {
      font-weight: 200;
      font-size: 0.85em;
      margin: 0;
      color: currentColor;
    }

    .g-button-primary {
      margin: 0 0 1.75em 0;
    }
  }
</style>
