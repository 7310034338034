
import {routePath} from '/store'

const getLink = ev => {
  let t = ev.target
  do {
    if (t.nodeName.toUpperCase() === 'A' && t.getAttribute('href')) {
      return t
    }
  } while(t = t.parentNode)
}


document.addEventListener('click', ev => {
  let link = getLink(ev)
  if (!link || link.hasAttribute('data-external-link')) return
  ev.preventDefault()
  const noScroll = link.getAttribute('data-no-scroll')
  const href = link.getAttribute('href')
  if (!noScroll) window.scrollTo(0, 0)
  window.history['pushState'](null, null, href)
  routePath.set(href)
})

window.onpopstate = ev => {
  routePath.set(window.location.pathname + window.location.search);
}
