
const w = (name) => (...args) => {
  try {
    return localStorage[name](...args)
  } catch (err) {
    console.error('local storage disabled')
  }
}

export default {
  clear: w('clear'),
  removeItem: w('removeItem'),
  setItem: w('setItem'),
  getItem: w('getItem')
}
