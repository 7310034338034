<script>
  import Loading from '/elements/Loading.svelte'
  export let submitting
</script>

<button {...$$props} disabled={submitting} >
  {#if submitting}
    <Loading />
  {:else}
    <slot></slot>
  {/if}
</button>
