export default (environments) => {
  const host = window.location.host
  const environment = Object
    .keys(environments)
    .find(env => 
      (typeof(environments[env]) == 'string' && environments[env] == host)
      || (typeof(environments[env]) == 'function' && environments[env](host))
      || (typeof(environments[env]) == 'object' && environments[env].includes(host)))

  !environment && console.warn(`No environment found for current url.`)

  return environment || 'development'
}

