<script>
  import ErrorMessage from '/elements/ErrorMessage.svelte'
  import Loading from '/elements/Loading.svelte'
  import EndpointSummary from '/elements/EndpointSummary.svelte'
  import EndpointStatsOverTime from '/elements/EndpointStatsOverTime.svelte'
  import {get} from 'svelte/store'
  import {route} from '/store'
  import url from '/util/url'
  import fetch from '/util/fetch'

</script>

<svelte:head>
  <title>Endpoint Stats | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

{#await fetch(url('api.endpointStats', {apiKeyId: $route.args.apiKeyId, endpointIdentifier: $route.args.endpointIdentifier}))}
  <Loading page />
{:then result}
  <div class='c' >
    <hr />
    <EndpointSummary stats={result} />
    <hr />
    <EndpointStatsOverTime
      urlName={'api.endpointStatsOverTime'} 
      urlArgs={{apiKeyId: $route.args.apiKeyId}} 
    />
  </div>
{:catch err}
   <ErrorMessage>Something went wrong :(</ErrorMessage>
{/await}

<style>
  hr:first-child {
    margin-bottom: 3em;
  }
  hr:not(:first-child) {
    margin: 3em 0;
  }
</style>
