import { API_URL } from './settings'

export default [
  {
    namespace: '',
    url: '',
    routes: {
      'landing': '',
      'pricing': 'pricing',
      'getStarted': 'get-started/:apiKeyId',
      'noUserGetStarted': 'no-user/get-started',
      'noUserStats': 'no-user/stats',
      'noUserEndpointStats': 'no-user/stats/:endpointIdentifier',
      'stats': 'stats/:apiKeyId',
      'endpointStats': 'stats/:apiKeyId/endpoints/:endpointIdentifier',
      'signup': 'signup',
      'login': 'login',
      'forgotPassword': 'forgot-password',
      'forgotPasswordSuccess': 'forgot-password-success',
      'resetPassword': 'reset-password/:token/:userId',
      'resetPasswordSuccess': 'reset-password-success',
      'paymentDetails': 'payment-details',
      'signupSuccess': 'success',
      'account': 'account',
    }
  },
  {
    namespace: 'api',
    url: API_URL,
    routes: {
      'me': 'me',
      'noUserStats': 'no-user/stats',
      'noUserEndpointStats': 'no-user/stats/:endpointIdentifier',
      'noUserEndpointStatsOverTime': 'no-user/stats/:endpointIdentifier/over-time',
      'noUserCreateApiKey': 'no-user/api-keys/create',
      'createAPIKey': 'api-key-create',
      'apiKey': 'api-keys/:apiKeyId',
      'stats': 'api-key/:apiKeyId/stats',
      'endpointStats': 'api-key/:apiKeyId/stats/:endpointIdentifier',
      'endpointStatsOverTime': 'api-key/:apiKeyId/stats/:endpointIdentifier/over-time',
      'newsletter': 'newsletter',
      'signup': 'auth/signup',
      'login': 'auth/login',
      'forgotPassword': 'auth/forgot-password',
      'resetPassword': 'auth/reset-password',
      'account': 'me/account',
      'accountPaymentMethod': 'me/account/payment-method',
      'accountSubscribe': 'me/account/subscribe',
      'accountUnsubscribe': 'me/account/unsubscribe',
      'accountAttachAPIKey': 'me/account/attach-api-key',
      'accountInvoices': 'me/account/invoices',
      'accountUsage': 'me/account/usage',
    }
  }
]
