<script>
  import {get} from 'svelte/store'
  import Modal from '/modal/Modal.svelte'
  import Input from '/elements/inputs/Input.svelte'
  import Loading from '/elements/Loading.svelte'
  import fetch from '/util/fetch'
  import url from '/util/url'
  import {modal, modalState} from '/store'
  import useRequest from '/util/useRequest.js'
  const account = useRequest(url('api.account'))

  let {name, id: apiKeyId} = get(modalState)
  let submitting

  const onSubmit = () => {
    submitting = true
    fetch(url('api.apiKey', { apiKeyId }), { method: 'DELETE' })
      .then(res => {
        modal.set(null)
        account.load()
      })
      .catch(err => {
        submitting = false
      })
  }

</script>

<Modal>
  <h3>Delete</h3>
  <p>Are you sure you want to delete the API Key "{name}"?</p>
  <form class='f' on:submit|preventDefault={onSubmit}>
    <div class='button-group'>
        <button type='button' class='g-button-primary small-button' on:click={() => modal.set(null)} >Cancel</button>
        <button class='g-button-primary small-button' disabled={submitting} >
          {#if submitting}
            <Loading />
          {:else}
            Yes, Delete
          {/if}
        </button>
    </div>
  </form>
</Modal>

<style>
  p {
    text-align: center;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    line-height: 1.5;
  }

  form.f {
    margin-top: 3em;
    flex-flow: row nowrap;
  }

</style>
