
<script>
  import {get} from 'svelte/store'
  import {apiKey} from '/store'
  import url from '/util/url'
  import ErrorMessage from '/elements/ErrorMessage.svelte'
  import Loading from '/elements/Loading.svelte'
  import GetStarted from '/elements/GetStarted.svelte'
  import localStorage from '/util/localStorage'

  const getApiKey = async () => {
    let existing = get(apiKey)
    if (existing) return existing

    let response = await fetch(
      url('api.noUserCreateApiKey'),
      {
        method: 'POST',
        data: JSON.stringify({}),
        headers: { 'Content-Type': 'application/json' }
      }
    )
    let json = await response.json()
    if (response.status > 299) {
      throw "Bad response status"
    }
    apiKey.set(json.apiKey)
    localStorage.setItem('apiKey', json.apiKey)
    return json
  }
</script>

{#await getApiKey()}
  <Loading page />
{:then _}
  <GetStarted apiKey={$apiKey} statsUrl={url('noUserStats')} />
{:catch _}
  <ErrorMessage>
    Something went wrong, please try again later.
  </ErrorMessage>
{/await}
