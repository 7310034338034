<script>
  import path from '@wasmuth/path'
  import {writable, get} from 'svelte/store'
  import {routePath, route} from '/store'
  import routeTo from '/router/route'
  import url from '/util/url'

  const AUTHED_ONLY_ROUTES = [
    'getStarted',
    'stats',
    'endpointStats',
    'paymentDetails',
    'signupSuccess',
    'account'
  ]

  const UNAUTHED_ONLY_ROUTES = [
    'noUserStats',
    'noUserGetStarted',
    'noUserEndpointStats',
    'landing',
    'login',
    'signup'
  ]

  const unsubscribe = route.subscribe(value => {
    let name = path(['definition', 'name'], value)
    let isAuthed = localStorage.getItem('token')
    if (isAuthed) {
      if (UNAUTHED_ONLY_ROUTES.includes(name)) {
        routeTo(url('account'))
      }
    } else {
      if (AUTHED_ONLY_ROUTES.includes(name)) {
        routeTo(url('landing'))
      }
    }
  })
</script>
