
<script>
  import SectionHeader from '/elements/SectionHeader.svelte'
  export let user
</script>

<svelte:head>
  <title>Account Overview | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

<div>
  <SectionHeader title='Account' icon='url(/assets/User.svg)' spaceBetween />
    <ul class='styled-list'>
    <li>Email <span class='value'>{user.email}</span></li>
  </ul>
</div>
